<template>
  <div class="basicInfoPage">
    <div class="basicInfo">
      <div class="title_text">
        <h2 class="main_title">{{ $t("userbasicInfoMainTip") }}</h2>
        <h3 class="sub_title">{{ $t("userbasicInfoSubTip") }}</h3>
      </div>
      <el-form
        :label-position="'top'"
        label-width="80px"
        ref="basicInfoForm"
        :model="basicInfoForm"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <div
          class="userInfoForm clearfix"
          :class="{ hasCompany: USER_INFO.company_id }"
        >
          <el-form-item label="Name*" v-if="IS_EN" prop="name_en">
            <el-input v-model="basicInfoForm.name_en" size="medium"></el-input>
          </el-form-item>
          <el-form-item label="姓名*" v-if="IS_ZH" prop="name_zh">
            <el-input v-model="basicInfoForm.name_zh" size="medium"></el-input>
          </el-form-item>
          <el-form-item
            :label="phoneLabel"
            v-if="needType == 'phone' || needType == 'all'"
          >
            <el-input
              v-model="basicInfoForm.telephone"
              size="medium"
              type="number"
              class="input-with-select"
            >
              <el-select
                v-model="basicInfoForm.areaCode"
                slot="prepend"
                :placeholder="$t('areaCode')"
                popper-class="registerAreaCodeList"
                class="basicInfoAreaCodeList"
                filterable
                :filter-method="areaCodeFilter"
              >
                <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeTitleFormat(LOCALE)"
                >
                  <p class="area_code">{{ item.area_code }}</p>
                  <p class="country textOverflow">
                    {{ item.country | areacodeAddressFormat(LOCALE) }}
                  </p>
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="emailLabel"
            v-if="needType == 'email' || needType == 'all'"
            prop="email"
          >
            <el-input v-model="basicInfoForm.email" size="medium"></el-input>
          </el-form-item>
          <el-form-item :label="positionLabel" prop="position">
            <el-input v-model="basicInfoForm.position"></el-input>
          </el-form-item>
          <el-form-item :label="companyNameLabel" prop="companyNameLabel">
            <el-input
              v-model="company_name"
              readonly
              @click.native="openPanel"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <registerCompany
        ref="registerCompany"
        @confirmJoin="confirmJoin"
      ></registerCompany>
      <div class="buttons tc">
        <el-button class="save" type="primary" @click="saveInfo" :loading="saveLoading">
          {{ $t("save") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import registerCompany from "~/baseComponents/registerCompany";
import { _decode } from "@/basePlugins/base64";
import { areaCodeList } from "@/baseUtils/AddressConfig";

import CountrySelect from "@/baseComponents/CountrySelect";
import CityComplete from "@/baseComponents/CityComplete";
import { priorFormat } from "@/basePlugins/filters";

export default {
  name: "basicInfo",
  components: {
    CountrySelect,
    CityComplete,

    registerCompany,
  },
  data() {
    return {
      company_name: "",
      source: this.PJSource,
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      countryLabelA: `${this.$t("country")}`,
      regionLabelA: `${this.$t("region")}`,
      phoneLabel: `${this.$t("phone")}`,
      emailLabel: `${this.$t("email")}`,
      positionLabel: `${this.$t("companyPosition")}`,
      companyNameLabelA: `${this.$t("companyName")}*`,
      companyNameLabel: `${this.$t("companyName")}`,
      countryLabel: `${this.$t("country")}`,
      regionLabel: `${this.$t("region")}`,
      saveLoading: false,
      isAlady: false,
      isNotAlady: true,
      loadingText: "",
      basicInfoForm: {
        name_en: "",
        country: null,
        countryA: null,
        region: "",
        regionA: "",
        areaCode: "+86",
        companyName: "",
        telephone: "",
        email: "",
        name_zh: "",
        position: "",
        companyId: "",
        getNoted: true,
        isAlady: 0,
      },
      companyInfo: {},
      matcedCompany: null,
      companyList: [],
      rules: {
        name_en: [
          {
            validator: (rule, value, callback) => {
              if (this.LOCALE == "en") {
                if (!value) {
                  callback(new Error(" "));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        name_zh: [
          {
            validator: (rule, value, callback) => {
              if (this.LOCALE == "zh") {
                if (!value) {
                  callback(new Error(" "));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        country: [
          {
            validator: (rule, value, callback) => {
              if (!this.basicInfoForm.companyName) {
                callback();
              } else {
                if (!this.basicInfoForm.country) {
                  callback(new Error(" "));
                } else {
                  callback();
                }
              }
            },
            trigger: "blur",
          },
        ],
        companyId: [
          {
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
      },
      user_id: "",
      needType: "",
    };
  },
  watch: {
    "basicInfoForm.isAlady": {
      handler() {
        this.clearComapnyInfo();
      },
    },
    "basicInfoForm.companyName": {
      handler(nv) {
        if (this.matcedCompany && nv != this.matcedCompany.value) {
          this.matcedCompany = null;
          this.basicInfoForm.country = null;
          this.basicInfoForm.region = "";
        }
      },
    },
  },
  created() {
    let queryData = _decode(this.$route.query.parameter);
    if (queryData.user_id) {
      this.user_id = queryData.user_id;
    }
    if (queryData.need_type) {
      this.needType = queryData.need_type;
    }
  },
  mounted() {
    this.getUserInfo();
    //this.getCompanyList();
    this.initForm();
  },
  methods: {
    confirmJoin(result) {
      this.companyInfo = result.result;
      this.company_name = result.name;
    },
    openPanel() {
      this.$refs.registerCompany.openPanel();
    },
    async getUserInfo() {
      let userInfo = await this.$store.dispatch("API_user/user_getUserInfo", {
        user_id: this.user_id,
      });
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },

    initForm() {
      let userInfo = Object.assign({}, this.USER_INFO);
      this.basicInfoForm.name_en = userInfo.name_en;
      this.basicInfoForm.name_zh = userInfo.name_zh;
      this.basicInfoForm.email = userInfo.email;
      this.basicInfoForm.areaCode = userInfo.area_code || "+86";
      this.basicInfoForm.position = userInfo[`job_title_${this.LOCALE}`];
    },
    changeCompany(id) {
      let current = this.companyList.find((item) => item.id == id);
      this.basicInfoForm.countryA = current.country;
      this.basicInfoForm.regionA =
        this.LOCALE == "en" ? current.region_en : current.region_zh;
    },
    clearComapnyInfo() {
      this.basicInfoForm.country = null;
      this.basicInfoForm.companyId = "";
      this.basicInfoForm.companyName = "";
      this.basicInfoForm.countryA = null;
      this.basicInfoForm.regionA = "";
      this.basicInfoForm.region = "";
      this.$refs.basicInfoForm.clearValidate();
    },
    saveInfo() {
      this.$refs.basicInfoForm.validate((vaild) => {
        if (vaild) {
          this.handleParams();
        }
      });
    },
    async handleParams() {
      this.saveLoading = true;
      let params = {
        user_id: this.user_id,
        name_zh: this.basicInfoForm.name_zh,
        name_en: this.basicInfoForm.name_en,

        email: this.basicInfoForm.email,
        mobile: this.basicInfoForm.telephone,
      };
      params[`job_title_${this.LOCALE}`] = this.basicInfoForm.position;
      if (this.needType == "phone" || this.needType == "all") {
        params.area_code = this.basicInfoForm.areaCode;
      }
      params = Object.assign(params, this.companyInfo);
      // if (this.companyInfo) {
      //   params[`company_name_${this.LOCALE}`] = this.company_name;
      //   params.country = this.companyInfo.country;
      //   params[`region_${this.LOCALE}`] = this.companyInfo.region;
      //   if (this.companyInfo.company_id) {
      //     params.company_id = this.companyInfo.company_id
      //   }
      // }

      try {
        let result = await this.$store.dispatch(
          "API_user/user_infoComplete",
          params
        );
        this.saveLoading = false;
        if (result.success) {
          this.$ssTip();
          let res = await this.$store.dispatch(
            "baseStore/baseSign_getUserInfo",
            {
              user_id: this.USER_INFO.id,
            }
          );
          this.$router.push("/");
          let result = await this.$store.dispatch(
            "baseStore/baseSign_getUserInfo",
            {
              user_id: this.USER_INFO.id,
            }
          );
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.saveLoading = false;
        this.$seTip();
      }
    },
    matchedData(data) {
      this.matcedCompany = data;
      this.basicInfoForm.country = data.country;
      this.basicInfoForm.region = data.region;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1600px) {
  .basicInfoPage {
    padding-top: 50px !important;
  }
}
/deep/ input[readonly="readonly"] {
  cursor: pointer;
}
.basicInfoPage {
  min-height: 100vh;
  background: #f1f4f7;
  padding: 30px 0px 20px;

  .basicInfo {
    width: 1300px;
    margin: 0 auto;
    padding: 40px 0px;
    background: #fff;

    border-radius: 4px;

    .title_text {
      text-align: center;
      margin-bottom: 20px;

      .main_title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .sub_title {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .userInfoForm {
      width: 410px;
      margin: 0 auto;

      &.hasCompany {
        width: 410px;
      }

      .el-form-item {
        width: 350px;
        float: left;
        margin: 0px 30px 15px;
      }
    }

    .companyInfoForm {
      margin-top: 10px;
    }

    .isAlady,
    .notAlady {
      width: 350px;
      margin: 0 auto;
      margin-top: 30px;

      .el-select {
        width: 350px;
      }
    }

    .isAlady {
      .el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #c0c4cc;
        cursor: not-allowed;
      }
    }

    .buttons {
      margin-top: 25px;

      .el-button {
        width: 350px;
      }
    }
  }
}
.save{
  background-color: #3384b1;
  border-color: #3384b1;
}
</style>
